module.exports = {
  title: 'WWB', // Required
  author: 'Robert Mills', // Required
  description: 'Accidental Chaos Monkey',
  primaryColor: '#3498db', // Required
  showHeaderImage: false,
  showShareButtons: true,
  postsPerPage: 10, // Required
  social: {
    website: 'https://worldwidebob.com',
    twitter: 'https://twitter.com/bot2600',
    linkedin: 'https://www.linkedin.com/in/bot2600/',
  },
  pathPrefix: '/',
  siteUrl: 'https://worldwidebob.com',
};
